@import url("https://use.typekit.net/fiy6nxt.css");

$darkBlue: #131221;
$darkGreen: #214420;
$darkGrey: #4d4d4d;
$green: #3f933d;
$grey: #707070;
$lightGrey: #a4a4a4;
$lightYellow: #f9f9f7;

$bodyFont: 'open-sans';
$headerFont: 'axia-stencil';

$tabletBreakpoint: 'screen and (max-width: 1023px)';
$mobileBreakpoint: 'screen and (max-width: 500px)';
