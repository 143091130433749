.frontPage {
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  & .spacing {
    grid-column: span 2;
    height: 20px;
  }
  & img {
    width: 100%;
  }
  & .intro {
    margin: auto 10px;
    padding: 40px;
    & h1 {
      text-align: center;
    }
    & .introButtons {
      display: flex;
      margin-top: 40px;
      justify-content: space-around;
      & button {
        font-size: 1.4em;
        min-height: 2em;
        margin: 10px;
        padding: 0 20px;
        border-radius: 10px;
        cursor: pointer;
        background: $green;
        & a {
          text-decoration: none;
          color: $darkBlue;
        }
      }
    }
  }
}

@media #{$tabletBreakpoint} {
  .frontPage {
    grid-template-columns: 100%;
    & .spacing {
      grid-column: auto;

    }
    & .intro {
      & .introButtons {
        & button {
          font-size: 1em;
          min-height: 2.6em;
        }
      }
    }
  }
}
