header {
  position: fixed;
  background: $darkBlue;
  top: 0;
  width: 100vw;
  border-bottom: 3px solid $lightYellow;
  display: flex;
  justify-content: space-between;
  & h1 {
    font-family: $headerFont;
    font-size: 5em;
    margin: 10px 20px;
  }
  & nav {
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
    & ul {
      display: flex;
      align-items: flex-end;
      & .menuDivider {
        align-self: center;
        height: 30px;
        width: 3px;
        background: $lightYellow;
      }
      & li {
        list-style: none;
        margin: 10px;
        & a {
          text-decoration: none;
          color: $lightYellow;
          font-size: 1.4em;
          transition: border 0.15s ease-in;
          border-bottom: 3px solid transparent;
          &:hover {
            border-bottom: 3px solid $lightYellow;
          }
        }
        & .activeNavLink {
          border-bottom: 3px solid rgba(249, 249, 247, 0.5);
        }
      }
    }
  }
  & .mobileButton {
    display: none;
  }
}

@media #{$tabletBreakpoint} {
  header {
    align-items: flex-end;
    & h1 {
      font-size: 4em;
    }
    & .mobileClosed {
      display: none;
    }
    & .mobileOpen {
      margin: 0;
      display: flex;
      position: fixed;
      padding-bottom: 10px;
      top: 100px;
      left: 0;
      width: 100vw;
      background: $darkBlue;
      border-bottom: 1px solid $lightYellow;
      box-shadow: 2px 2px 15px $lightYellow;
      & ul {
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        & .menuDivider {
          display: none;
        }
      }
    }
    & .mobileButton {
      margin: 20px;
      width: 40px;
      height: 39px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & span {
        background: $lightYellow;
        width: 40px;
        height: 3px;
      }
    }
  }
}
