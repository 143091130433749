.projectGrid {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  & .project {
    border-bottom: 3px solid $lightYellow;
    & img {
      max-width: 100%;
      cursor: zoom-in;
    }
    & .enlargedImage {
      position: fixed;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100vw;
      max-height: 100vh;
      z-index: 10;
      cursor: zoom-out;
    }
    & div {
      margin: 10px;
      & h3 {
        text-align: center;
      }
      & a {
        text-decoration: none;
        color: $lightYellow;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

#shade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: $darkBlue;
  opacity: 0.7;
}

@media #{$tabletBreakpoint} {
  .projectGrid {
    grid-template-columns: 100%;
    & .project {
      & img {
        max-width: calc(100vw - 20px);
      }
    }
  }
}
