.about {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-column-gap: 20px;
  & h2 {
    grid-column: span 2;
    text-align: center;
  }
  & img {
    max-width: 400px;
    margin: auto;
  }
  & div {
    padding: 20px;
    margin: auto;
  }
  & .highlight {
    font-size: 1.15em;
    color: $lightGrey;
  }
}

@media #{$tabletBreakpoint} {
  .about {
    grid-template-columns: 100%;
    & h2 {
      grid-column: auto;
    }
    & img {
      max-width: 300px;
    }
  }
}

@media #{$mobileBreakpoint} {
  .about {
    & img {
      max-width: 250px;
    }
  }
}
