.contactOverlay {
  position: fixed;
  background: $darkBlue;
  bottom: 60px;
  left: 20px;
  width: 400px;
  border: 1px solid $lightYellow;
  box-shadow: 2px 2px 15px $lightYellow;
  overflow: hidden;
  transition: height 0.5s, padding 0.4s 0.1s;
  & .closeButtonWrapper button {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 2em;
    line-height: 2em;
    border-radius: 50%;
    cursor: pointer;
  }
  & .contactForm {
    display: flex;
    flex-direction: column;
    & div {
      margin: 10px;
      display: flex;
      justify-content: space-between;
      & textarea {
        resize: none;
        height: 10em;
        width: 60%;
      }
      & input {
        height: 2em;
        width: 60%;
      }
      & button {
        margin-left: auto;
        font-size: 1.4em;
        height: 2em;
        padding: 0 20px;
        border-radius: 10px;
        cursor: pointer;
        background: $green;
        color: $darkBlue;
      }
    }
    & .emptyInput {
      border: none;
    }
    & .successInput {
      border: 2px solid green;
    }
    & .errorInput {
      border: 2px solid red;
    }
  }
}

@media #{$mobileBreakpoint} {
  .contactOverlay {
    width: 350px;
  }
}
